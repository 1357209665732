<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 200px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 200px" v-model="query.companyId" @change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码" style="width: 180px;" class="handle-input mr10"></el-input>
				<el-input size="small" v-model="query.uid" clearable placeholder="订单编号" style="width: 230px;" class="handle-input mr10"></el-input>
				<el-select size="small" style="width: 100px" v-model="query.status" filterable clearable placeholder="类型" class="handle-select mr10">
					<el-option v-for="item in rechargeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 100px" v-model="query.succeed" filterable clearable placeholder="状态" class="handle-select mr10">
					<el-option v-for="item in succeedList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_t_10" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" class="m_t_10" @click="handleAdd">添加停复机</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
			>
				<el-table-column prop="agent" label="代理商" align="center"></el-table-column>
				<el-table-column prop="company" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="phone" label="手机号" width="130" align="center"></el-table-column>
				<el-table-column prop="status" label="状态" width="90" align="center">
					<template #default="scope">
						<el-tooltip class="item" effect="dark" :content="scope.row.errMsg" placement="top" v-if="scope.row.status == '提交失败'">
							<span style="color: #f56c6c;">{{ scope.row.status }}</span>
						</el-tooltip>
						<span v-else>{{ scope.row.status }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="type" label="类型" align="center" width="100">
					<template #default="scope">
						<span v-if="scope.row.type == '停机'" class="status_color2 bgred">{{ scope.row.type }}</span>
						<span v-if="scope.row.type == '复机'" class="status_color2 bggreen">{{ scope.row.type }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="operateMsg" label="原因" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="时间" align="center" width="175"></el-table-column>
				<el-table-column prop="userPhone" label="操作者" align="center" width="130"></el-table-column>
				<el-table-column prop="uid" label="订单编号" width="350" align="center">
					<template #default="scope">
						<span style="cursor: pointer;">
							<i @click="getdownloadStopOrOpenRecord(scope.row.uid)" class="el-icon-download" :class="[theme3]" style="margin-right:10px;font-weight: 600;"></i>
							<span @click="copyUrl(scope.row.uid)">{{ scope.row.uid }}</span>
						</span>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[10, 30, 50, 100, 300]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
		<el-dialog title="添加停复机" v-model="addVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="addForm" ref="editForm">
				<el-form-item label="选择模式" prop="tab1">
					<el-radio-group v-model="tab1">
						<el-radio label="input">手动输入</el-radio>
						<el-radio label="fled">批量停开机模板</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="类型" prop="type">
					<el-radio-group v-model="addForm.type">
						<el-radio label="OPEN">开机</el-radio>
						<el-radio label="STOP">停机</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="手机号码" prop="phones" v-if="tab1 == 'input'">
					<el-input size="small" :rows="5" type="textarea" v-model="addForm.phones" auto-complete="off" placeholder="一行一个手机号码,一次最多添加500个号码"></el-input>
				</el-form-item>
				<el-form-item label="上传文件" prop="" v-else>
					<el-upload ref="upload" action="/api/dxt/FileStopOrOpen" :headers="token" :limit="1" :on-success="uploadSuccess" :auto-upload="false" :data="addForm">
						<el-button size="small" type="primary" :class="[theme]" style="margin-top: 50px;">选择文件</el-button>
						<div class="el-upload__tip">只能导入txt文件</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="原因" prop="operateMsg">
					<el-input size="small" :rows="5" type="textarea" v-model="addForm.operateMsg" auto-complete="off" placeholder="请输入停复机原因"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" v-if="tab1 == 'input'" @click="stopOrOpens">确定</el-button>
					<el-button size="small" type="primary" :class="[theme]" v-else @click="submitUpload">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { stopOrOpenRecord, stopOrOpen, downloadStopOrOpenRecord } from '../api/dijia.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			theme3: localStorage.getItem('theme3'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user'),
				status: '',
				phone: '',
				pageIndex: 1,
				pageSize: 10,
				uid: '',
				succeed: ''
			},
			agents: [],
			companys: [],
			tableData: [],
			pageTotal: 0,
			form: {
				label: '',
				value: [],
				companyId: ''
			},
			addForm: {
				phones: '',
				userId: localStorage.getItem('user'),
				operateMsg: '',
				type: 'OPEN'
			},
			token: { Authorization: localStorage.getItem('token') },
			rechargeList: [{ value: 0, label: '全部' }, { value: 1, label: '复机' }, { value: -1, label: '停机' }],
			succeedList: [{ value: 0, label: '全部' }, { value: 1, label: '成功' }, { value: -1, label: '失败' }],
			addVisible: false,
			multipleSelection: [],
			delList: [],
			tab1: 'input'
		};
	},

	created() {
		this.init();

		this.getstopOrOpenRecord();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},

		getstopOrOpenRecord() {
			this.query.userId = localStorage.getItem('user');
			stopOrOpenRecord(this.query).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getstopOrOpenRecord();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getstopOrOpenRecord();
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getstopOrOpenRecord();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
				this.options = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				//默认企业
				this.companys = res.data;
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			//代理商下拉
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
					console.log(this.options, 'fff');
					console.log(this.companys, 'this.companys');
				});
			}
		},
		copyUrl(item) {
			let cInput = document.createElement('input');
			cInput.value = item;
			document.body.appendChild(cInput);
			cInput.select();
			document.execCommand('copy');
			this.$message({
				type: 'succes',
				message: '复制成功'
			});
			document.body.removeChild(cInput);
		},
		handleAdd() {
			this.addVisible = true;
		},
		stopOrOpens() {
			stopOrOpen(this.addForm).then(res => {
				if (res.code == 200) {
					this.addVisible = false;
					this.$message.success(res.data);
					setTimeout(() => {
						this.getstopOrOpenRecord();
					}, 1000);
				} else {
					this.$message.error(res.message);
				}
			});
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		closeDialog() {
			this.addVisible = false;
			this.addForm.phones = '';
			this.addForm.operateMsg = '';
			this.addForm.type = 'OPEN';
			this.tab1 = 'input';
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.$refs.upload.clearFiles();
				this.addVisible = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('上传成功');
				this.getstopOrOpenRecord();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		getdownloadStopOrOpenRecord(orderId) {
			this.$confirm('确定要下载该批次数据吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						orderId: orderId
					};
					downloadStopOrOpenRecord(data).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '停复机记录' + orderId + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
					});
				})
				.catch(() => {});
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.recharge-box .el-radio__input {
	display: none;
}
.recharge-box .el-radio.is-bordered {
	padding: 8px 14px 0 5px;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 30px;
}
.status_color2 {
	color: #fff;
	background-color: #ff0000;
	border-radius: 3px;
	display: inline-block;
	width: 60px;
	height: 26px;
	text-align: center;
	line-height: 26px;
	font-size: 12px;
}

.bgred {
	background-color: #ff0000;
}
.bggreen {
	background-color: #67c23a;
}
</style>
