import request from '../utils/request';
import qs from "qs";

// AXB列表查询
export const setMealList = id => {
	return request.get('/api/setMeal/list')
};
// AXB添加
export const setMealAdd = param => {
	return request.post('/api/setMeal/add', qs.stringify(param))
};
// AXB修改
export const setMealUpdate = param => {
	return request.post('/api/setMeal/update', qs.stringify(param))
};
//AXB删除
export const setMealDelete = param => {
	return request.post('/api/setMeal/delete', qs.stringify(param))
};

//库存列表
export const inventoryList = param => {
	return request.post('/api/inventory/list', qs.stringify(param))
};
// 添加库存
export const inventoryUpload = param => {
	return request.post('/api/inventory/upload', qs.stringify(param), {
		responseType: 'blob'
	})
};

// 划拨号码给企业
export const inventoryOptionalNumber = param => {
	return request.post('/api/inventory/optionalNumber', qs.stringify(param))
};
// 查看已划拨号码
export const inventoryFindAssigned = param => {
	return request.post('/api/inventory/findAssigned', qs.stringify(param))
};

// 选购套餐
export const inventoryChoosePackage = param => {
	return request.post('/api/inventory/choosePackage', qs.stringify(param))
};

// 绑定
export const axbBind = param => {
	return request.post('/api/axb/bind', qs.stringify(param))
};
// 坐席续费
export const addDuration = param => {
	return request.post('/api/inventory/addDuration', qs.stringify(param))
};
// 解绑
export const axbUnbind = param => {
	return request.post('/api/axb/unbind', qs.stringify(param))
};
// 支付反馈
export const inventoryCheckPay = param => {
	return request.post('/api/inventory/checkPay', qs.stringify(param))
};


//AXB消费明细
export const inventoryExpenseDetail = param => {
	return request.post('/api/inventory/expenseDetail', qs.stringify(param))
};



// 所有地区号码
export const inventoryStatistics = id => {
	return request.get('/api/inventory/statistics')
};



// 导出回收客户
export const downloadCustomer = param => {
	return request.post('/api/customer/downloadCustomer', qs.stringify(param), {
		responseType: 'blob'
	})
};
// 分配回收客户
export const allotCustomers = param => {
	return request.post('/api/customer/allotCustomers', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
