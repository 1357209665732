<template>
	<div>
		<div style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 20px;width: 85%;">
			<el-button size="small" type="primary" :class="[theme]" @click="showForm">添加</el-button>
		</div>
		<el-table
			:data="tableData"
			border
			class="table el-table"
			ref="multipleTable"
			header-cell-class-name="table-header"
			:header-cell-style="{ 'text-align': 'center' }"
			@selection-change="handleSelectionChange"
			:row-style="{ height: '20px' }"
			:cell-style="{ padding: '6px' }"
			:row-class-name="tableRowClassName"
		>
			<el-table-column prop="agentName" width="400" label="所属代理商" align="center"></el-table-column>
			<el-table-column prop="hyperlink" label="链接地址" align="center"></el-table-column>
			<el-table-column prop="url" label="图片" width="300" align="center">
				<template #default="scope">
					<el-image
						v-if="scope.row.url"
						@click="bigImg(scope.row.url)"
						style="width: 50px; height: 50px; margin-bottom:-4px"
						:src="scope.row.url"
						:preview-src-list="srcList"
					></el-image>
					<span v-else></span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="190" align="center">
				<template #default="scope">
					<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="添加轮播" v-model="addVisible" width="450px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="代理商" prop="agentId" v-if="isAdmin">
					<el-select size="small" v-model="form.agentId" filterable clearable placeholder="请选择代理商">
						<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="超链接" prop="hyperlink"><el-input size="small" v-model="form.hyperlink"></el-input></el-form-item>
				<el-form-item label="轮播图片" prop="url">
					<el-upload
						:action="domain"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList"
						:auto-upload="false"
						ref="upload"
						accept="image/png,image/jpg,image/jpeg"
						:before-upload="beforeAvatarUpload"
						:on-change="getbusinessLicense"
						:data="token1"
						:http-request="httprequest"
						:class="{ hide: hideUpload }"
						:before-remove="beformRemove"
						:on-remove="handleRemove"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog('form')">取消</el-button>
					<el-button size="small" :loading="loading" type="primary" :class="[theme]" @click="addSlideshow('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { getSlideshowList, SlideshowAdd, SlideshowDelete } from '../api/notice';
import { fetchAgent } from '../api/agentIndex.js';
export default {
	data() {
		return {
			theme: localStorage.getItem('theme'),
			tableData: [],
			addVisible: false,
			limitCount: 1,
			hideUpload: false,
			form: {
				url: ''
			},
			domain: '',
			srcList: [],
			isAdmin: false,
			fileList: [],
			agents: []
		};
	},

	created() {
		const role = localStorage.getItem('ms_role');
		if (role === 'admin') {
			this.isAdmin = true;
		}
		this.getSlideshowLists();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getSlideshowLists() {
			getSlideshowList(localStorage.getItem('user')).then(res => {
				this.tableData = res.data;
			});
		},

		showForm() {
			this.addVisible = true;
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		},
		getbusinessLicense(file, fileList) {
			// 上传照片后隐藏上传按钮
			this.hideUpload = fileList.length >= this.limitCount;
			console.log(file, 'file');
			this.getBase64(file.raw).then(res => {
				this.form.url = res;
			});
		},

		beformRemove(fileList) {
			this.hideUpload = fileList.length >= this.limitCount;
		},
		addSlideshow() {
			this.form.userId = localStorage.getItem('user');
			SlideshowAdd(this.form).then(res => {
				if (res.code == 200) {
					this.$message.success('添加成功');
					this.addVisible = false;
					this.getSlideshowLists();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 转图片转vase64
		getBase64(file) {
			return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let imgResult = '';
				reader.readAsDataURL(file);
				reader.onload = function() {
					imgResult = reader.result;
				};
				reader.onerror = function(error) {
					reject(error);
				};
				reader.onloadend = function() {
					resolve(imgResult);
				};
			});
		},
		bigImg(url) {
			this.srcList[0] = url;
		},
		// 点击取消
		closeDialog(formName) {
			this.$refs.upload.clearFiles();
			this.hideUpload = false;
			this.addVisible = false;
			(this.form = {
				title: '',
				content: '',
				url: ''
			}),
				this.resetForm(formName);
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 删除照片
		handleRemove() {
			this.form.url = '';
		},
		// 删除操作
		handleDelete(index, id) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					SlideshowDelete(id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.pageTotal = this.pageTotal - 1;
							this.tableData.splice(index, 1);
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		upStor(row) {}
	}
};
</script>
<style scoped>
.red {
	color: #f56c6c;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}
</style>
