<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 200px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 200px" v-model="query.companyId" @change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码" style="width: 180px;" class="handle-input mr10"></el-input>
				<el-date-picker
					size="small"
					v-model="timeArr"
					type="daterange"
					unlink-panels
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_l_10" @click="handleSearch">搜索</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
			>
				<el-table-column prop="agentName" label="代理商" align="center"></el-table-column>
				<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="phone" label="手机号" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="被叫号码" align="center"></el-table-column>
				<el-table-column prop="time" label="时间" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[10, 30, 50, 100, 300]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { dxtErrRecord } from '../api/dijia.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user'),
				phone: '',
				pageIndex: 1,
				pageSize: 10
			},
			agents: [],
			companys: [],
			options: [],
			tableData: [],
			pageTotal: 0,
			timeArr: []
		};
	},
	created() {
		this.init();
		this.getTime();
		this.getdxtErrRecord();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getdxtErrRecord() {
			this.loading = true;
			this.tableData = [];
			// 日期
			if (this.timeArr === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let startTime = this.toDateTimeStr(this.timeArr[0]);
			let endTime = this.toDateTimeStr(this.timeArr[1]);
			this.query.startTime = startTime;
			this.query.endTime = endTime;
			dxtErrRecord(this.query).then(res => {
				this.loading = false;
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getdxtErrRecord();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getdxtErrRecord();
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getdxtErrRecord();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
				this.options = res.data;
			});
			let data = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data).then(res => {
				//默认企业
				this.companys = res.data;
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			//代理商下拉
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		},
		getTime() {
			//时间
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.timeArr.push(startTime);
			this.timeArr.push(endTime);
		},

		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}
			return y + '-' + m + '-' + d;
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.recharge-box .el-radio__input {
	display: none;
}
.recharge-box .el-radio.is-bordered {
	padding: 8px 14px 0 5px;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 30px;
}
.status_color2 {
	color: #fff;
	background-color: #ff0000;
	border-radius: 3px;
	display: inline-block;
	width: 60px;
	height: 26px;
	text-align: center;
	line-height: 26px;
	font-size: 12px;
}

.bgred {
	background-color: #ff0000;
}
.bggreen {
	background-color: #67c23a;
}
</style>
