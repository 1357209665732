<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 200px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 200px" v-model="query.companyId" @change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.state" filterable placeholder="订单状态" class="handle-select mr10" style="width: 120px">
					<el-option v-for="item in state" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.paymentType" filterable placeholder="支付方式" class="handle-select mr10" style="width: 120px">
					<el-option v-for="item in paymentType" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>

				<el-select size="small" v-model="query.shippingType" filterable placeholder="会员类型" class="handle-select mr10">
					<el-option v-for="item in shippingType" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.userPhone" clearable placeholder="手机号码/流水编号搜索" class="handle-input mr10" style="width: 180px;"></el-input>

				<el-date-picker
					size="small"
					v-model="timeArr"
					type="daterange"
					unlink-panels
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin-left: 10px;margin-top: 10px;" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" style="margin-left: 10px;margin-top: 10px;" @click="handleExport">导出账单</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				v-loading="loading"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="orderId" label="流水编号" align="center"></el-table-column>

				<el-table-column prop="orderAmount" label="订单价格" align="center" width="120">
					<template #default="scope">
						<span v-if="scope.row.orderAmount">{{ scope.row.orderAmount }}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="是否支付" align="center" width="120">
					<template #default="scope">
						<span v-if="scope.row.status == '未支付'" class="status_color red">{{ scope.row.status }}</span>
						<span v-if="scope.row.status == '已支付'" class="status_color green">{{ scope.row.status }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="paymentType" label="支付方式" align="center" width="120"></el-table-column>
				<el-table-column prop="shippingType" label="会员类型" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="手机号码" align="center"></el-table-column>
				<el-table-column prop="time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" width="90" align="center">
					<template #default="scope">
						<el-button size="small" type="text" v-if="scope.row.status == '未支付'" :disabled="false" icon="el-icon-edit" @click="handleEdit(scope.row.orderId)">
							补单
						</el-button>
						<el-button size="small" type="text" v-if="scope.row.status == '已支付'" :disabled="true" icon="el-icon-edit" @click="handleEdit(scope.row.orderId)">
							补单
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchDepartment } from '../api/index.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
import { getOrdersList, getOrdersMakeUp, downloadOrders } from '../api/cardType';
export default {
	name: 'report',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				agentId: '',
				paymentType: 0,
				state: 0,
				shippingType: 0,
				beginDate: '',
				endDate: '',
				userPhone: '',
				orderId: '',
				pageIndex: 1,
				pageSize: 30
			},
			fileList: [],
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			users: [],
			multipleSelection: [],
			delList: [],
			showId: false,
			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			isNotAdmin: true,
			isShow: false,
			isAgent: false,
			isAdmin: false,
			isCompany: false,
			pageTotal: 0,
			form: {
				companyId: '',
				agentId: '',
				paymentType: 0,
				state: 0,
				shippingType: 0,
				beginDate: '',
				endDate: '',
				userPhone: '',
				orderId: '',
				pageIndex: 1,
				pageSize: 30
			},
			state: [
				{
					value: 0,
					label: '全部'
				},
				{
					value: 1,
					label: '已支付'
				},
				{
					value: 2,
					label: '未支付'
				}
			],
			paymentType: [
				{
					value: 0,
					label: '全部'
				},
				{
					value: 1,
					label: '支付宝'
				},
				{
					value: 2,
					label: '微信'
				},
				{
					value: 3,
					label: '小程序'
				}
			],
			shippingType: [
				{
					value: 0,
					label: '全部'
				},
				{
					value: 1,
					label: '文字识别'
				},
				{
					value: 2,
					label: '坐席'
				},
				{
					value: 3,
					label: '电脑拨号'
				}
			],
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			path: process.env.BASE_URL,
			timeArr: [],

			options: []
		};
	},
	created() {
		this.getTime();
		this.init();
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
				this.options = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				//默认企业
				this.companys = res.data;
			});
		},
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			//代理商下拉
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
					console.log(this.options, 'fff');
					console.log(this.companys, 'this.companys');
				});
			}
		},
		companyChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			//企业下拉
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},

		getTime() {
			//时间
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.timeArr.push(startTime);
			this.timeArr.push(endTime);
		},
		getData() {
			this.loading = true;
			this.tableData = [];
			// 日期
			if (this.timeArr === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.timeArr[0]);
			let endDate = this.toDateTimeStr(this.timeArr[1]);
			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			this.query.currentUserId = localStorage.getItem('user');
			getOrdersList(this.query).then(res => {
				this.loading = false;
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		handleEdit(orderId) {
			// 二次确认删除
			this.$confirm('确定要补单吗？此操作可能造成金额损失，请谨慎操作', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = { orderId: orderId, userId: localStorage.getItem('user') };
					getOrdersMakeUp(data).then(res => {
						this.$message.success(res.message);
						this.getData();
					});
				})
				.catch(() => {});
		},
		toDateTimeStr(date) {
			console.log(date, '111111');
			console.log(date.getFullYear(), 'sssss');
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}

			return y + '-' + m + '-' + d;
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		handleExport() {
			// 日期
			if (this.timeArr === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.timeArr[0]);
			let endDate = this.toDateTimeStr(this.timeArr[1]);

			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			let data = {
				beginDate: this.query.beginDate,
				endDate: this.query.endDate
			};
			downloadOrders(data).then(res => {
				this.loading = false;
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				// console.log(response,'dddd')
				let date = new Date();
				let y = date.getFullYear(); //获取完整的年份(4位)
				let m = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let r = date.getDate(); //获取当前日(1-31)
				let fileName = '流水账单' + this.query.beginDate + '到' + this.query.endDate + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
			});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}

.status_color {
	color: #fff;
	background-color: #ff0000;
	border-radius: 3px;
	display: inline-block;
	width: 60px;
	height: 26px;
	text-align: center;
	line-height: 26px;
	font-size: 12px;
}
.red {
	background-color: #ff0000;
}
.green {
	background-color: #67c23a;
}
.mr10 {
	margin-right: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
