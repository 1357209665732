<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-if="isNormal"
					v-model="query.companyId"
					@change="companyChange"
					filterable
					clearable
					placeholder="企业"
					class="handle-select mr10"
					style="width: 300px"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-model="query.departmentId"
					@change="departmentChange"
					filterable
					clearable
					placeholder="部门"
					class="handle-select mr10"
					style="width: 180px"
				>
					<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.normalId" @change="userIdChange" filterable clearable placeholder="员工" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getrecoveryVisible2">批量回收</el-button>
			</div>
			<div style="margin-bottom: 40px;">
				<div style="margin: 20px 0;font-size: 22px;font-weight: 500;">线索回收池</div>
				<el-table
					:data="fileList"
					border
					class="table"
					ref="multipleTable"
					header-cell-class-name="table-header"
					:header-cell-style="{ 'text-align': 'center' }"
					@selection-change="handleSelectionChange"
					:row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }"
					:row-class-name="tableRowClassName"
				>
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column prop="fileName" label="线索名称" align="center"></el-table-column>
					<el-table-column prop="userName" label="归属人" align="center"></el-table-column>
					<el-table-column prop="count" label="可回收数量" align="center"></el-table-column>
					<el-table-column label="操作" width="240" align="center">
						<template #default="scope">
							<el-button size="small" type="text" v-if="scope.row.count > 0" @click="getrecoveryVisible(scope.row.id)">回收</el-button>
							<el-button size="small" type="text" v-else disabled>回收</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination">
					<el-pagination
						background
						layout="total, sizes, prev, pager, next, jumper"
						:current-page="query.pageIndex"
						:page-sizes="[10, 30, 50, 100]"
						:page-size="query.pageSize"
						:total="pageTotal"
						@size-change="handleSizeChange"
						@current-change="handlePageChange"
					></el-pagination>
				</div>
			</div>
			<div>
				<div style="margin: 20px 0;font-size: 22px;font-weight: 500;">线索分配池</div>
				<el-table
					:data="tableData"
					border
					class="table"
					ref="multipleTable"
					header-cell-class-name="table-header"
					:header-cell-style="{ 'text-align': 'center' }"
					:row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }"
					:row-class-name="tableRowClassName"
				>
					<el-table-column prop="fileName" label="分配文件名称" align="center"></el-table-column>
					<el-table-column prop="count" label="可分配数量" align="center"></el-table-column>
					<el-table-column prop="time" label="回收时间" align="center"></el-table-column>
					<el-table-column label="操作" width="340" align="center">
						<template #default="scope">
							<el-button size="small" type="text" @click="handleEdit(scope.row)" v-if="scope.row.count > 0">分配</el-button>
							<el-button size="small" type="text" disabled v-else>分配</el-button>
							<el-button size="small" type="text" icon="el-icon-upload2" v-if="scope.row.count > 0" @click="handleExport(scope.row)">导出</el-button>
							<el-button size="small" type="text" icon="el-icon-upload2" disabled v-else>导出</el-button>
							<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-dialog :title="fileName" v-model="fileNameVisible" width="600px" @close="closeDialog('fileForm')" :close-on-click-modal="false">
				<el-form :model="fileForm" ref="fileForm" label-width="120px">
					<el-form-item label="分配类型">
						<el-radio-group v-model="fileForm.type" @change="typeChange">
							<el-radio label="0">平均分配</el-radio>
							<el-radio label="1">手动分配</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="fileForm.type == 0" label="可分配线索数量">
						<span>{{ fileForm.totals }}条</span>
					</el-form-item>
					<el-form-item v-if="fileForm.type == 0" label="被分配线索数量">
						<el-input size="small" v-model="multipleSelection.length" disabled v-if="multipleSelection.length > 0"></el-input>
						<el-input size="small" v-model="fileForm.totals2" v-else></el-input>
					</el-form-item>
					<el-form-item v-if="fileForm.type == 1" label="可分配线索数量">
						<span>{{ fileForm.totals }}条</span>
					</el-form-item>
					<el-form-item label="部门">
						<el-checkbox-group v-model="fileForm.departmentList" @change="handleCheckedCitiesChange" size="small">
							<el-checkbox v-for="item in departmentList" :label="item.value" :key="item.value" border style="width: 180px;margin-bottom:10px;margin-right: 20px;">
								{{ item.label }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="员工" v-if="nameList.length > 0">
						<el-checkbox-group v-model="fileForm.nameList" @change="handleCheckedCitiesChange2" size="small">
							<el-checkbox
								v-for="(item, index) in nameList"
								:label="item.value"
								:key="item.value"
								border
								style="width: 180px;margin:0;margin-bottom:10px;display: flex;flex-direction: row;position: relative;"
							>
								{{ item.label }}
								<el-input
									size="small"
									v-if="fileForm.type == 1"
									v-model="fileForm.number[index]"
									placeholder="分配条数"
									style="position: absolute;left: 200px;top: -2px;"
									@input="getNumber(item, fileForm.number[index])"
								></el-input>
								<!-- -->
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item>
						<el-button size="small" style="margin-left: 55%;" @click="closeDialog('fileForm')">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="addAllotCustomers">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

			<el-dialog title="回收线索" v-model="recoveryVisible" width="450px" @close="closeDialog('query')" :close-on-click-modal="false">
				<el-form :model="query" :rules="query" ref="query" label-width="100px">
					<el-form-item label="提示:" prop="fileName"><span style="font-size: 12px;color: #ff0000;">线索回收后，数据进入“线索池”，可重新分配和导出 !</span></el-form-item>
					<el-form-item label="线索池名称:" prop="fileName">
						<el-select
							size="small"
							style="width: 240px"
							v-model="query.fileName"
							filterable
							clearable
							placeholder="请选择线索池"
							:popper-append-to-body="false"
							no-data-text="注意：首次使用线索回收功能，找到“线索管理”----点击“线索池”---找到“新建线索池”--编辑命名“线索池名称”+选择勾选可见范围----点击“确认”。"
						>
							<el-option v-for="item in tableData" :key="item.id" :label="item.fileName" :value="item.id">{{ item.fileName }}</el-option>
						</el-select>
						<span v-else style="font-size: 12px;color: #ff0000;display: flex;"></span>
					</el-form-item>
					<el-form-item>
						<el-button size="small" style="margin-left: 55%;" @click="recoveryVisible = false">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="recycleData">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
	recycleCustomers,
	findNormalFileByUserId,
	getUserByDepartmentId,
	downloadCustomer,
	allotCustomers,
	recycleData,
	recycieFile,
	deleteRecycleFile,
	customerAllocation
} from '../api/assignLeads';
import { fetchDepartment, fetchUsersByDepartmentId } from '../api/index.js';
import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
export default {
	name: 'user',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				agentId: '',
				time: '',
				departmentId: '',
				userId: '',
				normalId: '',
				normalFileId: '',
				fileName: '',
				pageSize: 10
			},
			agents: [],
			companys: [],
			departments: [],
			tableData: [],
			recoveryData: 0,
			agentDis: false,
			isAdmin: false,
			isAgent: false,
			isNormal: false,
			pageTotal: 0,
			companyDis: false,
			loading: false,
			invalidateDate: 0,
			role: '',
			fileNameVisible: false,
			recoveryVisible: false,
			form: {
				departmentId: '',
				userId: '',
				time: '',
				nameList: [], //可分配员工
				departmentList: [], //可分配部门
				type: '0',
				list: [],
				number: []
			},
			departmentList: [], //可分配部门
			nameList: [], //可分配员工
			fileList: [], //可分配的文件
			fileName: '',
			labelArr: [],
			valueArr: [],
			multipleSelection: [],
			delList: [],
			dataSurplusNumber: 0,
			dataNumber: 0,
			fileForm: {
				nameList: [], //可分配员工
				departmentList: [], //可分配部门
				type: '0',
				list: [],
				number: [],
				totals: '',
				totals2: ''
			}
		};
	},
	created() {
		this.role = localStorage.getItem('ms_role');
		this.init();
		if (this.role === 'admin') {
			this.isAdmin = true;
			this.agentDis = true;
			this.companyDis = true;
			this.isNormal = true;
		}
		if (this.role === 'agent') {
			this.isAgent = true;
			this.companyDis = true;
			this.isNormal = true;
		}
	},
	computed: {
		dataSurplusNumber() {
			this.dataSurplusNumber = 0;
			for (let i = 0; i < this.form.number.length; i++) {
				console.log(this.form.number[i], '我是空的吗');
				if (this.form.number[i]) {
					this.dataSurplusNumber += this.form.number[i] - 0;
				}
			}
			return this.dataSurplusNumber;
		}
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			let data = {
				currentUserId: localStorage.getItem('user')
			};
			fetchInitAgent(data).then(res => {
				if (res.data) {
					this.invalidateDate = res.data.validCallTime;
				}
			});
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});

			fetchDefaultCompanies(data).then(res => {
				if (this.role == 'company' || this.role == 'department') {
					let data = {
						companyId: '',
						currentUserId: localStorage.getItem('user')
					};
					fetchDepartment(data).then(res => {
						this.departments = res.data;
						this.departmentList = res.data;
						this.query.companyId = res.data[0].value;
						this.getrecycleCustomers();
						this.recycieFiles();
					});
				} else {
					this.companys = res.data;
				}
			});
		},

		getrecycleCustomers() {
			let data = {
				userId: localStorage.getItem('user'),
				companyId: this.query.companyId,
				departmentId: this.query.departmentId,
				normalId: this.query.normalId,
				normalFileId: this.query.normalFileId,
				time: this.query.time,
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex
			};
			recycleCustomers(data).then(res => {
				if (res.code == 200) {
					this.pageTotal = res.data.total;
					this.fileList = res.data.list;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		agentChange(agentId) {
			console.log(agentId, 'agentIdagentIdagentId');
			this.query.agentId = agentId;
			this.form.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		},
		companyChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.departmentList = res.data;
				this.query.pageIndex = 1;
				this.getrecycleCustomers();
				this.recycieFiles();
			});
		},
		//员工
		departmentChange(departmentId) {
			let data = {
				departmentId: departmentId,
				currentUserId: localStorage.getItem('user')
			};
			fetchUsersByDepartmentId(data).then(res => {
				this.users = res.data;
				this.getrecycleCustomers();
				this.recycieFiles();
			});
		},
		userIdChange(normalId) {
			this.query.normalId = normalId;
			this.findNormalFileByUserId();
			this.getrecycleCustomers();
			this.recycieFiles();
		},
		findNormalFileByUserId() {
			findNormalFileByUserId(this.query.normalId).then(res => {
				this.fileList = res.data;
				this.getrecycleCustomers();
				this.recycieFiles();
			});
		},
		fileChange(e) {
			this.query.normalFileId = e;
			this.getrecycleCustomers();
			this.recycieFiles();
		},
		// 触发搜索按钮
		handleSearch() {
			this.getrecycleCustomers();
			this.recycieFiles();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		//回收数据
		getrecoveryVisible(id) {
			this.query.normalFileId = id;
			this.recoveryVisible = true;
		},
		getrecoveryVisible2() {
			if (this.multipleSelection.length > 0) {
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.query.normalFileId += this.multipleSelection[i].id + ',';
				}
				this.recoveryVisible = true;
			} else {
				this.$message.error('请选择批量分配的文件');
			}
		},
		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		closeDialog(formName) {
			this.fileForm.type = '0';
			this.fileForm.number = [];
			this.labelArr = [];
			this.$refs[formName].resetFields();
			this.fileNameVisible = false;
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getrecycleCustomers();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getrecycleCustomers();
		},
		recycleData() {
			let data = {
				userId: localStorage.getItem('user'),
				normalFileId: this.query.normalFileId,
				highSeasDataId: this.query.fileName
			};
			recycleData(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.recoveryVisible = false;
					this.getrecycleCustomers();
					this.recycieFiles();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		recycieFiles() {
			let data = {
				userId: localStorage.getItem('user'),
				companyId: this.query.companyId
			};
			recycieFile(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		typeChange(e) {
			this.fileForm.departmentList = [];
			this.fileForm.nameList = [];
			this.fileForm.type = e;
			if (e == 1) {
				this.fileForm.number = [];
				this.labelArr = [];
			}
			this.list = [];
			this.valueArr = [];
			this.$refs['fileForm'].resetFields();
		},
		getNumber(item, e) {
			// let obj = {
			// 	label: item.value,
			// 	value: e
			// };
			// this.valueArr.push(obj);

			this.valueArr.push(e);
		},

		//分配的员工id字符串化
		handleCheckedCitiesChange2(value) {
			this.labelArr = value;
			if (this.fileForm.type == 0) {
				this.valueArr.push('0');
			}
		},

		handleCheckedCitiesChange(value) {
			this.fileForm.nameList = [];
			this.fileForm.number = [];
			if (value) {
				if (value.length > 0) {
					getUserByDepartmentId(value).then(res => {
						if (res.code == 200) {
							this.nameList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					this.nameList = [];
				}
			}
		},
		//分配弹窗
		allotCustomers() {
			this.fileNameVisible = true;
			this.fileForm.departmentList = [];
			this.fileForm.nameList = [];
		},
		//确认分配
		addAllotCustomers() {
			this.list = [];
			for (let i = 0; i < this.labelArr.length; i++) {
				if (this.labelArr[i]) {
					let obj = {};
					obj.label = this.labelArr[i];
					obj.value = this.valueArr[i];
					this.list.push(obj);
				}
			}
			// let labelArr = ['6482c630208f5743ccf93154', '63b6970eee9de633aa7352cc'];
			// let valueArr = [
			// 	{ label: '6482c630208f5743ccf93154', value: '1' },
			// 	{ label: '63b6970eee9de633aa7352cc', value: '2' },
			// 	{ label: '6482c630208f5743ccf93154', value: '3' },
			// 	{ label: '63b6970eee9de633aa7352cc', value: '4' },
			// 	{ label: '6482c630208f5743ccf93154', value: '5' },
			// 	{ label: '63b6970eee9de633aa7352cc', value: '23' },
			// 	{ label: '63b6970eee9de633aa7352cc', value: '1' },
			// 	{ label: '6482c630208f5743ccf93154', value: '2' },
			// 	{ label: '6482c630208f5743ccf93154', value: '3' },
			// 	{ label: '6482c630208f5743ccf93154', value: '11' }
			// ];
			// // 2个数组循环对比，最后获取到[{ label: '63b6970eee9de633aa7352cc', value: '1' },{ label: '6482c630208f5743ccf93154', value: '11' }]
			// // 创建一个空的结果数组
			// let result = [];

			// for (let i = 0; i < valueArr.length; i++) {
			// 	for (let j = 0; j < labelArr.length; j++) {
			// 		if (valueArr[i].label === labelArr[j]) {
			// 			valueArr[i].value = Math.max(valueArr[i].value, valueArr[j].value);
			// 			break;
			// 		}
			// 	}
			// }

			// console.log(valueArr);
			// return;
			if (this.list.length > 0) {
				let str = '';
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				let data = {
					highSeasDataId: this.highSeasDataId,
					ids: str,
					type: parseInt(this.fileForm.type),
					totals: this.fileForm.totals2,
					list: this.list
				};
				customerAllocation(JSON.stringify(data)).then(res => {
					this.list = [];
					this.valueArr = [];
					this.fileForm.number = [];
					if (res.code == 200) {
						this.nameList = [];
						this.fileForm.number = [];
						this.fileForm.list = [];
						this.valueArr = [];
						this.labelArr = [];
						this.fileNameVisible = false;
						this.fileForm.type = '0';
						this.$message.success(res.message);
						this.getrecycleCustomers();
						this.recycieFiles();
					} else {
						if (res.message) {
							this.fileForm.number = [];
							this.fileForm.list = [];
							this.fileForm.departmentList = [];
							this.fileForm.nameList = [];
							this.$message.error(res.message);
						} else {
							this.$message.error('分配失败');
						}
					}
				});
			} else {
				this.$message.error('请选择分配的员工');
			}
		},
		//导出
		handleExport(row) {
			let data = {
				highSeasDataId: row.id,
				userId: localStorage.getItem('user')
			};
			downloadCustomer(data).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				// console.log(response,'dddd')
				let date = new Date();
				let y = date.getFullYear(); //获取完整的年份(4位)
				let m = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let r = date.getDate(); //获取当前日(1-31)
				let fileName = row.fileName + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
			});
		},
		handleEdit(row) {
			this.dataNumber = row.count;
			this.highSeasDataId = row.id;
			this.fileForm.totals = row.count;
			this.fileForm.totals2 = row.count;
			this.fileName = '分配' + row.fileName + '线索池';
			this.fileNameVisible = true;
			this.fileForm.departmentList = [];
			this.fileForm.nameList = [];
		},
		handleDelete(row) {
			this.$confirm('是否要删除此回收的文件？', '提示', {
				type: 'warning',
				confirmButtonText: '删除',
				cancelButtonText: '不删除',
				closeOnClickModal: false
			})
				.then(() => {
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
						.then(() => {
							let param = {
								highSeasDataId: row.id
							};
							deleteRecycleFile(param).then(res => {
								if (res.code == 200) {
									this.$message.success('删除成功');
									this.getrecycleCustomers();
									this.recycieFiles();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				})
				.catch(() => {
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
						.then(() => {
							let param = {
								highSeasDataId: row.id
							};
							deleteRecycleFile(param).then(res => {
								if (res.code == 200) {
									this.$message.success('删除成功');
									this.getrecycleCustomers();
									this.recycieFiles();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				});
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	
	display: inline-block;
}
.handle-select {
	width: 120px;
}

.handle-input {
	width: 300px;
	display: inline-block;
}
.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}
.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
/deep/.el-select-dropdown__empty {
	width: 220px !important;
	color: #ff0000;
	padding: 10px;
	text-align: left;
}
</style>
<style>
.import-visible .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
</style>
