<template>
	<div class="color-box">
		<div @click="go(color1, '#4f7afd')" class="bg_4f7afd">默认</div>
		<div @click="go(color2, '#f05b72')" class="bg_f05b72">蔷薇色</div>
		<div @click="go(color3, '#426ab3')" class="bg_426ab3">杜若色</div>
		<div @click="go(color4, '#2e3a1f')" class="bg_2e3a1f">蓝海松茶</div>
		<div @click="go(color5, '#faa755')" class="bg_faa755">柑子色</div>
		<div @click="go(color6, '#65c294')" class="bg_65c294">若竹</div>
		<div @click="go(color7, '#c88400')" class="bg_c88400">萱草色</div>
		<div @click="go(color8, '#9b95c9')" class="bg_9b95c9">克莱因蓝</div>
		<div @click="go(color9, '#002FA7')" class="bg_002FA7">蒂芙尼蓝</div>
		<div @click="go(color10, '#FF6347')" class="bg_FF6347">提香红</div>
		<div @click="go(color11, '#B05923')" class="bg_B05923">申布伦黄</div>
	</div>
</template>

<script>
export default {
	name: 'setColor',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			color1: 'bg_4f7afd',
			color2: 'bg_f05b72',
			color3: 'bg_426ab3',
			color4: 'bg_2e3a1f',
			color5: 'bg_faa755',
			color6: 'bg_65c294',
			color7: 'bg_c88400',
			color8: 'bg_9b95c9',
			color9: 'bg_002FA7',
			color10: 'bg_FF6347',
			color11: 'bg_B05923'
		};
	},

	created() {},
	methods: {
		go(e, c) {
			console.log(e);
			console.log(c);
			this.theme = e;
			localStorage.setItem('theme', e);
			localStorage.setItem('theme2', e + 2);
			localStorage.setItem('theme3', e + 3);
			localStorage.setItem('theme4', e + 4);
			localStorage.setItem('theme6', e + 6);
			this.$message.success('设置成功');
			location.reload();
		}
	}
};
</script>

<style scoped>
.color-box {
	display: flex;
}
.color-box div {
	width: 160px;
	height: 80px;
	margin-right: 20px;
	text-align: center;
	line-height: 80px;
	color: #fff;
	cursor: pointer;
}
</style>
