<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.city" filterable clearable placeholder="请选择城市" class="handle-select m_r_10">
					<el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.label"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleAdd">添加</el-button>
				<a :href="`${path}AXB.xlsx`" class="down" style="margin:10px 10px;" download="AXB模板.xlsx" :class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" type="primary" :class="[theme]" @click="toOptionalNumber">划拨</el-button>
			</div>
			<div class="city-box">
				<div v-for="item in cityList" :key="item.label" style="cursor: pointer;" @click="tabCity(item)">
					{{ item.label }}
					<span>{{ item.value }}</span>
					<i :class="[theme3]" class="el-icon-shopping-cart-full"></i>
				</div>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="city" label="城市" align="center"></el-table-column>
				<el-table-column prop="phone" label="号码" align="center"></el-table-column>
				<el-table-column prop="id" label="号码编号" align="center"></el-table-column>
				<el-table-column prop="status" label="状态" align="center">
					<template #default="scope">
						<span v-if="scope.row.status == true">已出卡</span>
						<span v-else>存在</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="toOptionalNumber2(scope.row)">划拨</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[10, 30, 90, 200]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
		<el-dialog title="添加" v-model="addVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="addForm" ref="editForm">
				<el-form-item label="上传文件" prop="minutes" class="customer">
					<el-upload
						ref="upload"
						action="/api/inventory/upload"
						:headers="token"
						:limit="1"
						:on-success="uploadSuccess"
						:on-preview="handlePreview"
						:on-remove="handleRemove"
						:auto-upload="false"
						:data="uploadData"
					>
						<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
						<div class="el-upload__tip">只能导入excel文件</div>
					</el-upload>
				</el-form-item>

				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitUpload">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="号码划拨" v-model="optionalVisible" width="520px" @close="closeDialog2">
			<el-form label-width="120px" :model="optionalForm" ref="editForm">
				<el-form-item label="代理商" prop="minutes" v-if="isAdmin">
					<el-select
						size="small"
						style="width: 100%;"
						@change="agentChange"
						v-model="optionalForm.agentId"
						filterable
						clearable
						placeholder="请选择代理商"
						class="handle-select mb10"
					>
						<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="minutes">
					<el-select
						size="small"
						style="width: 100%"
						v-model="optionalForm.companyId"
						@change="fileChange"
						filterable
						clearable
						placeholder="请选择企业"
						class="handle-select"
					>
						<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="套餐类型" prop="type" v-else>
					<el-radio-group v-model="optionalForm.type" @change="typeChange">
						<el-radio v-for="item in payList" :key="item.value" :label="item.value" :value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="扫码支付" v-show="weixinUrl">
					<div id="qrcode" ref="qrcode" style="position: relative;"></div>
					<div>
						<img src="../assets/img/wxpay.png" alt="" style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" />
						<span style="font-size: 18px;color: #000;">金额：{{ total_fee / 100 }}元</span>
					</div>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog2">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitoptionalNumber">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { inventoryList, inventoryUpload, inventoryOptionalNumber, inventoryFindAssigned, inventoryStatistics, inventoryCheckPay } from '../api/setMeal.js';
import { fetchDefaultCompanies, fetchCompanyByAgentId } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
import qs from 'qs';
import QRCode from 'qrcodejs2';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			theme3: localStorage.getItem('theme3'),
			query: {
				city: '',
				pageIndex: 1,
				pageSize: 20
			},
			tableData: [],
			pageTotal: 0,
			cityList: [],
			addForm: {
				pminutes: '',
				price: '',
				type: '1'
			},
			addVisible: false,
			optionalVisible: false,
			multipleSelection: [],
			agents: [],
			companys: [],
			optionalForm: {
				agentId: '',
				companyId: '',
				ids: '',
				type: ''
			},
			isAdmin: false,
			isAgent: false,
			role: '',
			weixinUrl: false,
			url: '',
			total_fee: '',
			qr: '',
			payList: [
				{
					value: 'wxpay',
					label: '微信支付'
				},
				{
					value: 'alipay',
					label: '支付宝支付'
				}
			],
			clearInterval: null,
			out_trade_no: '',
			uploadData: {
				userId: localStorage.getItem('user')
			},
			path: process.env.BASE_URL
		};
	},

	created() {
		this.role = localStorage.getItem('ms_role');
		this.getData();
		this.init();
		this.inventoryStatistics();
		if (this.role === 'admin') {
			this.isAdmin = true;
		}

		if (this.role === 'agent') {
			this.isAgent = true;
		}
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
				this.options = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				//默认企业
				this.companys = res.data;
			});
		},
		getData() {
			let data = {
				city: this.query.city,
				pageIndex: this.query.pageIndex,
				pageSize: this.query.pageSize
			};
			inventoryList(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		agentChange(agentId) {
			this.optionalForm.agentId = agentId;
			this.optionalForm.companyId = '';
			//代理商下拉
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		},
		companyChange(companyId) {
			this.optionalForm.companyId = companyId;
			this.optionalForm.userId = '';
			//企业下拉
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},

		closeDialog2() {
			clearInterval(this.clearInterval);
			this.optionalVisible = false;
		},
		inventoryStatistics() {
			inventoryStatistics().then(res => {
				if (res.code == 200) {
					this.cityList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		tabCity(item) {
			this.query.city = item.label;
			this.getData();
		},
		handleAdd() {
			this.addVisible = true;
			this.addForm = {};
		},
		handleEdit(row) {
			this.optionalVisible = true;
			this.addForm.price = row.price * 100;
			this.addForm.id = row.id;
		},
		submitAdd() {
			let data = {
				userId: localStorage.getItem('user'),
				price: this.addForm.price,
				minutes: this.addForm.minutes,
				type: this.addForm.type
			};
			setMealAdd(data).then(res => {
				if (res.code == 200) {
					this.$message.success('添加成功');
					this.addForm = {};
					this.getData();
				} else {
					this.$message.error(res.message);
				}
				this.addVisible = false;
			});
		},

		closeDialog() {
			this.addVisible = false;
			this.addForm.phones = '';
		},
		// 删除操作
		handleDelete(index, id) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						id: id
					};
					setMealDelete(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toOptionalNumber() {
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.optionalForm.ids += this.multipleSelection[i].id + ',';
			}
			if (this.optionalForm.ids === '') {
				this.$message.error(`请选择数据`);
				return;
			} else {
				this.optionalVisible = true;
			}
		},
		toOptionalNumber2(row) {
			this.optionalForm.ids = row.id;
			this.optionalVisible = true;
		},
		submitoptionalNumber() {
			this.$refs.qrcode.innerHTML = '';
			let data = {
				userId: localStorage.getItem('user'),
				companyId: this.optionalForm.companyId,
				ids: this.optionalForm.ids,
				type: this.optionalForm.type
			};
			inventoryOptionalNumber(data).then(res => {
				if (res.code == 200) {
					if (this.isAdmin) {
						this.$message.success('划拨成功');
						this.optionalVisible = false;
						this.getData();
					} else {
						this.out_trade_no = res.data.out_trade_no;
						if (this.optionalForm.type == 'wxpay') {
							this.weixinUrl = true;
							this.url = res.data.url;
							this.total_fee = res.data.total_fee;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.url, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						} else if (this.optionalForm.type == 'alipay') {
							let divForm = document.getElementsByTagName('divform');
							if (divForm.length) {
								document.body.removeChild(divForm[0]);
							}
							const div = document.createElement('divform');
							div.innerHTML = res.data.orderForm;
							document.body.appendChild(div);
							document.forms[1].setAttribute('target', '_blank');
							document.forms[1].submit();
						}
						this.clearInterval = setInterval(res => {
							this.getinventoryCheckPay();
						}, 2000);
					}
				} else {
					this.$message.error(res.message);
				}
			});
		},
		typeChange(e) {
			if (e == 'alipay') {
				this.weixinUrl = false;
				this.$refs.qrcode.innerHTML = '';
			}
			clearInterval(this.clearInterval);
		},
		getinventoryCheckPay(orderId) {
			let data = {
				orderId: this.out_trade_no
			};
			inventoryCheckPay(data).then(res => {
				if (res.code == 200) {
					if (res.data == true) {
						this.$message.success('支付成功');
						this.$refs.qrcode.innerHTML = '';
						this.payUrl = false;
						this.$router.go(0);
						clearInterval(this.clearInterval);
					}
				} else {
					this.$message.error(res.message);
				}
			});
		},

		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},

		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		//导入上传
		uploadSuccess(success) {
			if (success.code == 200) {
				if (success.data == '导入成功0条数据') {
					this.$message.error(success.data);
				} else {
					this.$message.success(success.data);
					this.getData();
					this.addVisible = false;
				}
			} else {
				this.$message.error(success.message);
			}
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.city-box {
	display: flex;
	margin: 20px 0;
}
.city-box div {
	float: left;
	margin-right: 20px;
}
.city-box div span {
	margin-right: 6px;
}
.customer .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
	line-height: 50px !important;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 6px 12px;
	font-size: 12px;
	border-radius: 4px;
	
	display: inline-block;
}
</style>
