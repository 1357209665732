<template>
	<div>
		<div class="container">
			<div class="handle-box"><el-button size="small" type="primary" :class="[theme]" @click="handleAdd" v-if="isAdmin">添加</el-button></div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange"
			>
				<el-table-column prop="type" label="套餐类型" align="center">
					<template #default="scope">
						<span v-if="scope.row.type == 1">套餐</span>
						<span v-if="scope.row.type == 2">加量包</span>
						<span v-if="scope.row.type == 3">套餐外消费</span>
					</template>
				</el-table-column>
				<el-table-column prop="minutes" label="时长" align="center">
					<template #default="scope">
						<span>{{ scope.row.minutes }}分钟</span>
					</template>
				</el-table-column>
				<el-table-column prop="price" label="价格" align="center">
					<template #default="scope">
						<span>{{ scope.row.price }}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="id" label="套餐编号" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="title" v-model="addVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="addForm" ref="editForm">
				<el-form-item label="套餐时长" prop="minutes">
					<el-input size="small" v-model="addForm.minutes" placeholder="请输入套餐时长" :disabled="disabled">
						<template slot="append">
							分钟
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="套餐价格" prop="price">
					<el-input size="small" type="text" v-model="addForm.price" placeholder="请输入价格">
						<template slot="append">
							分
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="套餐类型" prop="type">
					<el-radio-group v-model="addForm.type" :disabled="disabled">
						<el-radio label="1" value="1">套餐</el-radio>
						<el-radio label="2" value="2">加量包</el-radio>
						<el-radio label="3" value="3">套餐外消费</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" v-if="title == '添加'" @click="submitAdd">确定</el-button>
					<el-button size="small" type="primary" :class="[theme]" v-if="title == '修改'" @click="submitUpload">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { setMealList, setMealAdd, setMealUpdate, setMealDelete } from '../api/setMeal.js';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {},
			title: '添加',
			tableData: [],
			addForm: {
				pminutes: '',
				price: '',
				type: '1'
			},
			disabled: true,
			addVisible: false,
			isAdmin: false,
			isCompany: false,
			isAgent: false
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'company') {
			this.isCompany = true;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
		}
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getData() {
			setMealList().then(res => {
				if (res.code == 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		handleAdd() {
			this.title = '添加';
			this.disabled = false;
			this.addVisible = true;
			this.addForm = {};
		},
		handleEdit(row) {
			this.title = '修改';
			this.disabled = true;
			this.addVisible = true;
			this.addForm.price = row.price * 100;
			this.addForm.id = row.id;
		},
		submitAdd() {
			let data = {
				userId: localStorage.getItem('user'),
				price: this.addForm.price,
				minutes: this.addForm.minutes,
				type: this.addForm.type
			};
			setMealAdd(data).then(res => {
				if (res.code == 200) {
					this.$message.success('添加成功');
					this.addForm = {};
					this.getData();
				} else {
					this.$message.error(res.message);
				}
				this.addVisible = false;
			});
		},

		submitUpload() {
			let data = {
				userId: localStorage.getItem('user'),
				price: this.addForm.price,
				id: this.addForm.id
			};
			setMealUpdate(data).then(res => {
				if (res.code == 200) {
					this.$message.success('修改成功');
					this.addForm = {};
					this.getData();
				} else {
					this.$message.error(res.message);
				}
				this.addVisible = false;
			});
		},
		closeDialog() {
			this.addVisible = false;
			this.addForm.phones = '';
		},
		// 删除操作
		handleDelete(index, id) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						id: id
					};
					setMealDelete(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
</style>
