<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					style="width: 260px"
					v-if="isAgent"
					v-model="query.companyId"
					@change="companyChange"
					filterable
					clearable
					v-show="isNormal"
					placeholder="企业"
					class="handle-select mr10"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<el-collapse-transition>
				<el-form ref="queryList" :model="query" label-width="90px" label-position="left" class="query-list">
					<el-form-item label="当前进度:" v-if="customerScheduleList.length > 0">
						<el-radio-group v-model="query.schedule" :key="scheduleKey">
							<el-radio :label="item.index" v-for="item in customerScheduleList" :key="item.index" @click.enter.prevent="getSchedule(item.index)">
								{{ item.str }}
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="客户来源:" v-if="ustomerSourceList.length > 0">
						<el-radio-group v-model="query.source" :key="sourceKey">
							<el-radio :label="item" v-for="(item, index) in ustomerSourceList" :key="index" @click.enter.prevent="getSource(item)">{{ item }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="跟进时间:" v-show="showForm">
						<el-radio-group v-model="query.notUpdated" :key="notUpdatedKey">
							<el-radio label="3" @click.enter.prevent="getNotUpdated('3')">大于3天</el-radio>
							<el-radio label="7" @click.enter.prevent="getNotUpdated('7')">大于7天</el-radio>
							<el-radio label="10" @click.enter.prevent="getNotUpdated('10')">大于10天</el-radio>
							<el-radio label="15" @click.enter.prevent="getNotUpdated('15')">大于15天</el-radio>
							<el-radio label="30" @click.enter.prevent="getNotUpdated('30')">大于30天</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="性别:" v-show="showForm">
						<el-radio-group v-model="query.gender" :key="genderKey">
							<el-radio label="1" @click.enter.prevent="getGender('1')">男</el-radio>
							<el-radio label="2" @click.enter.prevent="getGender('2')">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="查询内容:" v-show="showForm">
						<el-radio-group v-model="query.type" :key="typeKey">
							<el-radio label="1" @click.enter.prevent="getType('1')">手机号码</el-radio>
							<el-radio label="2" @click.enter.prevent="getType('2')">客户姓名</el-radio>
							<el-radio label="3" @click.enter.prevent="getType('3')">备注</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="输入内容:" v-show="showForm">
						<el-input size="small" v-model="query.conditions" clearable placeholder="请输入内容" style="width: 260px"></el-input>
					</el-form-item>
					<el-form-item label="创建时间:" v-show="showForm">
						<el-date-picker
							v-model="timeArr"
							style="width: 260px"
							type="daterange"
							unlink-panels
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							@change="getTime()"
							size="small"
						></el-date-picker>
					</el-form-item>
				</el-form>
			</el-collapse-transition>
			<div class="loyo-folding-btn loadMore" v-if="showForm == true" @click="showForm = !showForm">
				<a href="javascript:;" data-toggle="tooltip" data-placement="right" title="" data-original-title="展开 "><i class="el-icon-arrow-up"></i></a>
			</div>
			<div class="loyo-folding-btn close__Btn down2" v-else @click="showForm = !showForm">
				<a href="javascript:;" data-toggle="tooltip" data-placement="right" title="" data-original-title="收起"><i class="el-icon-arrow-down"></i></a>
			</div>

			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin:0 10px 20px 20px;" @click="handleSearch">查询</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-circle-plus-outline" style="margin:0 10px 20px 0;" @click="addData">手动添加</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2" style="margin:0 10px 20px 0;" @click="handleDownload">导出</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-delete" style="margin:0 0 20px 0;" @click="getRemoveCrm2" v-show="isNormal">批量删除</el-button>

			<a :href="`${path}crm.xls`" class="down mr10 " download="公海客户模板.xlsx" :class="[theme]">
				<i class="el-icon-download"></i>
				下载模板
			</a>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-s-promotion" style="margin:0 0 20px 0;" @click="getPublicData">领取客户</el-button>
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" @click="uploadExcel">导入</el-button>

			<el-row>
				<el-col :span="24">
					<el-table
						:data="tableData"
						border
						class="table"
						ref="multipleTable"
						v-loading="loading"
						header-cell-class-name="table-header"
						@row-click="showData"
						:cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName"
						@selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="schedule" label="当前进度" align="center" width="120">
							<template #default="scope">
								<div
									v-if="scope.row.scheduleIndex == '1'"
									style="color: #fff;background-color: #f56c6c;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '2'"
									style="color: #fff;background-color: #00a0ea;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '3'"
									style="color: #fff;background-color: #F39801;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '4'"
									style="color: #fff;background-color: #eb6100;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '5'"
									style="color: #fff;background-color: #8fc320;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '6'"
									style="color: #fff;background-color: #4CB8A6;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '7'"
									style="color: #fff;background-color: #63C9EE;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '8'"
									style="color: #fff;background-color: #68F25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '9'"
									style="color: #fff;background-color: #BBF25C;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div
									v-else-if="scope.row.scheduleIndex == '10'"
									style="color: #fff;background-color: #9D5CF2;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;"
								>
									{{ scope.row.schedule }}
								</div>
								<div v-else style="color: #fff;background-color: #000;border-radius: 4px;width: 70px;text-align: center;margin: 0 auto;">
									{{ scope.row.schedule }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="customerName" label="客户姓名" width="120" align="center"></el-table-column>
						<el-table-column prop="gender" label="客户性别" width="80" align="center"></el-table-column>
						<el-table-column prop="phoneNumber" label="客户号码" width="130" align="center"></el-table-column>
						<el-table-column prop="createdAt" label="创建时间" width="190" align="center"></el-table-column>
						<el-table-column prop="updatedAt" label="跟进时间" width="190" align="center"></el-table-column>
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
						<el-table-column label="操作" width="190" align="center" fixed="right">
							<template #default="scope">
								<el-button size="small" type="text" icon="el-icon-delete" class="red" @click.enter.stop="getRemoveCrm(scope.row.id)" v-show="isNormal">
									删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>

			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 300, 500, 1000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
		<div class="drawer-box" :modal-append-to-body="false" append-to-body>
			<el-drawer title="客户详情" v-model="drawer" direction="rtl" size="75%" :before-close="handleClose" modal="false" :with-header="false">
				<div class="details-box">
					<div class="header">
						<div class="user-box">
							<div class="user-name">
								<div v-if="customerDetails.gender == '男' || customerDetails.gender == ''"><img src="../assets/img/man.png" alt="" /></div>
								<div v-if="customerDetails.gender == '女'"><img src="../assets/img/girl.png" alt="" /></div>
								<div class="name">
									<div>客户姓名</div>
									<el-input
										size="small"
										v-model="customerDetails.customerName"
										type="text"
										style="width: 200px;"
										:disabled="drawerInputDis"
										:value="customerDetails.customerName || '无'"
										:class="[{ bordis: drawerInputDis == true }]"
									></el-input>
								</div>
							</div>
							<div class="user-btn">
								<el-button size="small" type="primary" :class="[theme]" style="margin-left: 10px;" @click="handleEdit">编辑</el-button>
								<i class="el-icon-refresh-right" style="font-size: 26px;position: relative;top: 6px;margin-left: 10px;color: #8e8e8e;" @click="Nodrawer"></i>
								<i class="el-icon-close" style="font-size: 26px;position: relative;top: 1px;margin-left: 10px;color: #8e8e8e;" @click="drawer = false"></i>
							</div>
						</div>
						<div class="user-info">
							<div class="list">
								<div class="name-label">电话号码</div>
								<div class="name-data">{{ customerDetails.phoneNumber }}</div>
							</div>
							<div class="list">
								<div class="name-label">来源</div>
								<div class="name-data">
									<i style="width: 6px;height: 6px;background-color: #3f8AFD;border-radius: 50%;display: inline-block;"></i>
									{{ customerDetails.source || '无' }}
								</div>
							</div>
							<div class="list">
								<div class="name-label">当前跟进人</div>
								<div class="name-data">{{ customerDetails.normalName }}</div>
							</div>
							<div class="list">
								<div class="name-label">添加时间</div>
								<div class="name-data">{{ customerDetails.createdAt }}</div>
							</div>
						</div>
					</div>
					<div class="nav-tab">
						<div class="ul-box">
							<div
								v-for="item in customerScheduleList"
								:key="item.index"
								@click="tapNav(item.index)"
								:class="customerDetails.scheduleIndex >= item.index ? 'active' : ''"
							>
								{{ item.str }}
							</div>
						</div>
					</div>
					<div class="drawer-content">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="联系人信息" name="contactInformation">
								<div class="title">
									<span></span>
									基本信息
								</div>
								<div class="call-record-box">
									<div class="call-list">
										<div class="item-info">
											<span class="lat">客户姓名:</span>
											<el-input
												size="small"
												v-model="form.customerName"
												type="text"
												style="width: 200px;"
												:disabled="drawerInputDis"
												:value="customerDetails.customerName"
												:class="[{ bordis: drawerInputDis == true }]"
											></el-input>
										</div>
										<div class="item-info">
											<span class="lat">电话号码:</span>
											<el-input
												size="small"
												v-model="form.phoneNumber"
												type="text"
												style="width: 200px;"
												:disabled="drawerInputDis"
												:value="customerDetails.phoneNumber"
												:class="[{ bordis: drawerInputDis == true }]"
											></el-input>
										</div>
										<div class="item-info">
											<span class="lat">客户性别:</span>
											<el-select
												size="small"
												v-model="form.gender"
												filterable
												clearable
												placeholder="请选择性别"
												style="width: 200px;"
												:disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]"
											>
												<el-option key="1" label="男" value="男"></el-option>
												<el-option key="2" label="女" value="女"></el-option>
											</el-select>
										</div>

										<div class="item-info">
											<span class="lat">添加时间:</span>
											<el-input
												size="small"
												style="width: 200px;"
												v-model="form.createdAt"
												type="text"
												disabled
												:value="customerDetails.createdAt"
												:class="[{ bordis: drawerInputDis == true }]"
											></el-input>
										</div>

										<div class="item-info">
											<span class="lat">最后跟进时间:</span>

											<el-input
												size="small"
												v-model="form.updatedAt"
												type="text"
												disabled
												style="width: 200px;"
												:value="customerDetails.updatedAt"
												:class="[{ bordis: drawerInputDis == true }]"
											></el-input>
										</div>
										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">部门:</span>
											<el-select
												size="small"
												v-model="query.departmentId"
												@change="departmentChange"
												filterable
												clearable
												v-show="isNormal"
												placeholder="部门"
												class="handle-select mr10"
												style="width: 180px"
												:disabled="drawerInputDis"
											>
												<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
										</div>

										<div class="item-info" v-if="isCompany || isAgent">
											<span class="lat">跟进人:</span>
											<el-select
												size="small"
												v-model="customerDetails.normalName"
												filterable
												clearable
												placeholder="请选择跟进员工"
												v-if="isCompany || isAgent"
												disabled
											>
												<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
											</el-select>
											<span v-else style="font-size: 12px;color: #ff0000;">需要选择部门才能更换跟进人</span>
										</div>
										<div class="item-info" v-for="item in customerDetails.customizeVo" :key="item">
											<span class="lat">{{ item.label }}:</span>
											<el-input
												size="small"
												v-if="item.type == 'multiline-text'"
												v-model="item.textVal"
												type="text"
												placeholder="请输入内容"
												style="width: 200px;"
												:disabled="drawerInputDis"
											></el-input>
											<el-date-picker
												class="placeholder-style"
												v-if="item.type == 'date'"
												v-model="item.textVal"
												value-format="yyyy-MM-dd"
												placeholder="选择日期"
												style="width: 200px;"
												size="small"
												:disabled="drawerInputDis"
											></el-date-picker>
											<el-time-select
												class="placeholder-style"
												v-if="item.type == 'time'"
												type="date"
												v-model="item.textVal"
												placeholder="选择时间"
												style="width: 200px;"
												size="small"
												:disabled="drawerInputDis"
											></el-time-select>
											<el-radio-group v-model="item.textVal" v-if="item.type == 'radio-drop-down-box'" @change="radioChange">
												<el-radio v-for="items in item.options" :label="items.str" :key="items.index" v-model="item.textVal" :disabled="drawerInputDis">
													{{ items.str }}
												</el-radio>
											</el-radio-group>
											<div v-if="item.type == 'multi-select-drop-down-box'">
												<el-checkbox-group v-model="item.multiSelectDropDownBox">
													<el-checkbox
														v-for="items in item.options"
														:key="items.index"
														:label="items.str"
														v-model="items.checked"
														:disabled="drawerInputDis"
													>
														{{ items.str }}
													</el-checkbox>
												</el-checkbox-group>
											</div>
											<div v-if="item.type == 'region'" style="display: flex;flex-direction: row;">
												<el-cascader
													v-model="addressSelections"
													:options="addressData"
													@change="handleAddress"
													style="width: 230px"
													placeholder="请选择省市区"
													filterable
													:disabled="drawerInputDis"
												/>
												<el-input
													size="small"
													v-model="detail"
													placeholder="请输入地址"
													@change="handleChangedetail"
													:disabled="drawerInputDis"
													style="width: 300px;margin-left: 10px;"
												></el-input>
											</div>
										</div>
										<div class="item-info">
											<span class="lat">备注：</span>
											<el-input
												size="small"
												style="width: 200px;"
												v-model="form.remark"
												type="textarea"
												:disabled="drawerInputDis"
												:class="[{ bordis: drawerInputDis == true }]"
												:value="customerDetails.remark"
											></el-input>
										</div>
									</div>
								</div>

								<div v-if="drawerInputDis == false" style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
									<el-button size="small" @click="getNoupdateIntentionCustomer">取消</el-button>
									<el-button size="small" type="primary" :class="[theme]" @click="updateIntentionCustomer()">确定</el-button>
								</div>
							</el-tab-pane>
							<el-tab-pane label="通话记录" name="callLog">
								<el-row>
									<el-col :span="24">
										<el-table
											:data="followUpRecordData"
											border
											class="table"
											ref="multipleTable"
											v-loading="loading"
											header-cell-class-name="table-header"
											@row-click="showData2"
											:header-cell-style="{ 'text-align': 'center' }"
											:key="Math.random()"
										>
											<el-table-column prop="phoneNumber" label="拨打号码" align="center"></el-table-column>
											<el-table-column prop="callDuration" label="通话时长(秒)" align="center"></el-table-column>
											<el-table-column prop="callStatus" label="拨打状态" align="center"></el-table-column>
											<el-table-column prop="phoneModel" label="号码归属地" align="center"></el-table-column>
											<el-table-column label="通话录音" prop="recordPath" width="350" align="center">
												<template #default="scope">
													<audio :id="scope.row.recordPath" controls="controls" preload="auto">
														<source :src="scope.row.recordPath" type="audio/mpeg" />
														<source :src="scope.row.recordPath" type="audio/ogg" />
														<source :src="scope.row.recordPath" type="audio/wav" />
													</audio>
												</template>
											</el-table-column>
											<el-table-column prop="createdAt" label="拨打时间" align="center" width="190"></el-table-column>
										</el-table>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane label="拜访记录" name="callRecord">
								<el-row>
									<el-col :span="16">
										<div style="position: relative;top: 0;left: 0;min-height: 500px;">
											<div class="add-journal" @click="showcallRecordBox">+</div>
											<el-timeline :reverse="true" style="width: 400px;margin-left:20%;">
												<el-timeline-item v-for="(item, index) in customerDetails.journal" :key="index" :timestamp="item.label">
													{{ item.value }}
												</el-timeline-item>
											</el-timeline>
										</div>
									</el-col>
									<el-col :span="8" v-if="callRecordBox">
										<textarea
											type="textarea"
											:autosize="{ minRows: 5 }"
											placeholder="请输入拜访内容"
											prefix-icon="el-icon-search"
											v-model="journal"
											style="margin-bottom: 20px;width: 98%;min-height: 100px;padding: 2px 6px;border: 1px solid #f1f2f6;"
										></textarea>
										<div style="display: flex;flex-direction: row;justify-content: space-evenly;">
											<el-button size="small" @click="hidecallRecordBox">取消</el-button>
											<el-button size="small" type="primary" :class="[theme]" @click="GetIntentionCustomeraddJournals">确定</el-button>
										</div>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane label="操作日志" name="problemRecord">
								<el-timeline-item v-for="(item, index) in customerDetails.operationLog" :key="index" :timestamp="item.label">{{ item.value }}</el-timeline-item>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</el-drawer>
		</div>

		<el-dialog title="新建CRM客户" v-model="addVisible" width="500px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="客户姓名" prop="customerName"><el-input size="small" v-model="form.customerName"></el-input></el-form-item>
				<el-form-item label="电话号码" prop="phoneNumber"><el-input size="small" v-model="form.phoneNumber"></el-input></el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select size="small" v-model="form.gender" filterable clearable placeholder="请选择性别" style="width: 100%;" :disabled="roleDis">
						<el-option key="1" label="男" value="男"></el-option>
						<el-option key="2" label="女" value="女"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="数据来源" prop="source">
					<el-radio :label="item" v-for="(item, index) in ustomerSourceList" :key="index" v-model="form.source" @click.enter.prevent="getSource2(item)">
						{{ item }}
					</el-radio>
				</el-form-item>

				<div v-for="(item, index) in customList" :key="index">
					<el-form-item :label="item.label">
						<div v-if="item.type == 'multiline-text'"><el-input size="small" v-model="item.textVal" type="text" placeholder="我是文本"></el-input></div>
						<div v-if="item.type == 'date'"><el-date-picker size="small" v-model="item.textVal" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker></div>
						<div v-if="item.type == 'time'">
							<el-time-select size="small" type="date" v-model="item.textVal" placeholder="选择时间" style="width: 100%"></el-time-select>
						</div>
						<div v-if="item.type == 'radio-drop-down-box'">
							<el-radio :label="items" v-for="items in item.options" :key="items.index" v-model="item.textVal"></el-radio>
						</div>
						<div v-if="item.type == 'multi-select-drop-down-box'">
							<el-checkbox-group v-model="item.multiSelectDropDownBox">
								<el-checkbox :label="items" v-for="items in item.options" :key="items.index"></el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-if="item.type == 'region'">
							<el-cascader v-model="addressSelections" :options="addressData" @change="handleAddress" placeholder="请选择省市区" filterable />
							<el-input size="small" v-model="detail" placeholder="请输入地址" @change="handleChangedetail"></el-input>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="备注"><el-input size="small" type="textarea" v-model="form.remark"></el-input></el-form-item>

				<el-form-item label="部门" v-show="isNormal">
					<el-select
						size="small"
						v-model="query.departmentId"
						@change="departmentChange"
						filterable
						clearable
						placeholder="部门"
						class="handle-select mr10"
						style="width: 180px"
					>
						<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="更进人" prop="normalName" v-show="isNormal">
					<el-select size="small" v-model="form.normalName" filterable clearable placeholder="员工" class="handle-select mr10" style="width: 100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="addVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getAddIntentionCustomer('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="释放原因" v-model="shifang" width="500px" @close="closeshifangDialog" :close-on-click-modal="false">
			<el-form :model="shifangbox" :rules="rules" ref="form" label-width="100px" class="import-visible">
				<el-form-item label="原因" prop="shifangVal">
					<el-radio-group v-model="shifangbox.shifangVal" @change="shifangRadioChange" style="border: 1px solid #EBEEF5;padding: 8px;">
						<el-radio style="width: 100%;margin-bottom: 10px;" v-for="(item, index) in shifangList" :key="index" :label="item.str">{{ item.str }}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: flex-end;">
				<el-button size="small" @click="closeshifangDialog" style="width: 100px;height: 40px;">取消</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="deleteIntentionCustomers" style="width: 100px;height: 40px;">确定</el-button>
			</span>
		</el-dialog>

		<el-dialog v-model="transferVisible2" title="领取数据" width="450px" :close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="跟进员工" prop="userId">
					<el-select size="small" v-model="form.userId" placeholder="请选择跟进员工" :disabled="userDis" style="width:100%">
						<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
					<el-button size="small" @click="transferVisible2 = false">取消</el-button>
					<el-button type="primary" :class="[theme]" size="small" @click="getPublicData2">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="user-box">
			<el-dialog title="导入文件" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/intentionCustomer/upload"
					:headers="token"
					:limit="1"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:on-success="uploadSuccess"
					:auto-upload="false"
					:data="form"
					:before-upload="beforeAvatarUpload"
				>
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能上传excel文件</div>
				</el-upload>
				<el-button size="small" type="primary" :class="[theme]" style="margin-left: 80%;margin-top: 20px;" @click="submitUpload">确定</el-button>
			</el-dialog>

			<el-dialog v-model="filterBox" width="250px" title="重复数据">
				<div class="filter-box" style="width: 200px;height: 400px;overflow: scroll;">
					<div v-for="item in filterListData">{{ item }}</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
	download,
	intentionCustomergetDetails,
	addIntentionCustomer,
	updateIntentionCustomer,
	updateSchedule,
	getfindCallRecord,
	deleteIntentionCustomer,
	GetIntentionCustomeraddJournal,
	downloadIntentionCustomerList,
	CRMpublicData,
	getPublicData,
	turnaroundCustomer,
	removeCrm
} from '../api/recordIndex';
import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers } from '../api/index.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
import { getListCustomize, getCustomerSchedule, getCustomerSource, getCustomerRelease } from '../api/tag.js';
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				departmentId: '',
				userId: '',
				schedule: '',
				type: '',
				conditions: '',
				normalId: '',
				gender: '',
				startTime: '',
				endTime: '',
				pageIndex: 1,
				pageSize: 30,
				source: ''
			},
			ressderRegionList: [],
			showForm: true,
			fileList: [],
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			users: [],
			multipleSelection: [],
			delList: [],
			followUpRecordData: [], //通话记录
			returnVisitData: [], //拜访记录
			input3: '',
			combinationSelect: '',

			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			isNormal: true,
			isNotAdmin: true,
			isAdmin: false,
			drawer: false,
			isAgent: false,
			isCompany: false,
			pageTotal: 0,
			form: {
				normalName: '',
				phoneNumber: '',
				gender: '',
				customerCompany: '',
				mailbox: '',
				customerIdentity: '',
				remark: '',
				customerName: '',
				source: '',
				userId: localStorage.getItem('user'),
				multilineText: '',
				customDate: '',
				customTime: '',
				radioDropDownBox: '',
				multiSelectDropDownBox: [],
				customize: []
			},
			rules: {
				phoneNumber: [{ required: true, message: '请输入电话号码', trigger: 'change' }],
				normalName: [{ required: true, message: '请选择跟进员工', trigger: 'change' }]
			},
			form2: {
				customerName: '',
				phoneNumber: '',
				intention: '',
				remark: ''
			},

			loading: false,
			activeName: 'contactInformation',
			customerDetails: '',
			idArr: '',
			rowIndex: '',
			lastId: '',
			nextId: '',
			prevDis: false,
			nextDis: false,
			drawerInputDis: true, //抽屉里面的input是否可以编辑
			tapnav: 1, //抽屉里的初防
			callRecordBox: false,
			scheduleKey: false,
			journal: '',
			timeArr: [],
			customList: [],
			customerScheduleList: [], //进度列表
			ustomerSourceList: [], //来源列表
			customize: {},
			shifang: false,
			shifangList: [],
			shifangbox: {
				shifangVal: ''
			},
			shifangId: '',
			// 选中数组
			ids: [],
			//地址信息
			addressData: regionData,
			addressSelections: [],
			warehouse: 'warehouse',
			province: '',
			city: '',
			town: '',
			detail: '',
			addressStr: '',

			transferVisible2: false,
			deviceName: null, //是否显示拨号
			path: process.env.BASE_URL,
			upload: false,
			token: { Authorization: localStorage.getItem('token') },
			filterBox: false, //导入后的弹出
			filterListData: []
		};
	},
	computed: {
		collapse() {
			return this.$store.state.collapse;
		}
	},
	created() {
		this.role = localStorage.getItem('ms_role');
		if (this.role === 'company') {
			this.isCompany = true;
			this.query.companyId = localStorage.getItem('company');
			this.getData();
			this.getListCustomize();
			this.getCustomerSchedule();
			this.getCustomerSource();
		}
		if (this.role === 'normal') {
			this.isNormal = false;
			this.query.companyId = localStorage.getItem('company');
			this.getData();
			this.getListCustomize();
			this.getCustomerSchedule();
			this.getCustomerSource();
		}
		if (this.role === 'admin' || this.role === 'agent') {
			this.isNotAdmin = false;
		}
		if (this.role === 'admin') {
			this.isAdmin = true;
		}
		if (this.role === 'agent' || this.role === 'admin') {
			this.isAgent = true;
		}
		if (this.role === 'department') {
			this.query.companyId = localStorage.getItem('company');
			this.getData();
			this.getListCustomize();
			this.getCustomerSchedule();
			this.getCustomerSource();
		}
		this.init();
		setInterval(() => {
			this.deviceName = localStorage.getItem('deviceName');
		}, 1000);
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		handleAddress(e) {
			for (let i = 0; i < this.addressSelections.length; i++) {
				if (i == 0) {
					this.province = CodeToText[this.addressSelections[i]];
					this.addressStr = this.province;
				}
				if (i == 1) {
					this.city = CodeToText[this.addressSelections[i]];
					this.addressStr = this.addressStr + '-' + this.city;
				}
				if (i == 2) {
					this.town = CodeToText[this.addressSelections[i]];
					this.addressStr = this.addressStr + '-' + this.town;
				}
			}
		},
		handleChangedetail(e) {
			this.detail = e;
			this.addressStr = this.addressStr + '-' + e;
		},
		addData() {
			this.addVisible = true;
			this.form.normalName = '';
			this.form.phoneNumber = '';
			this.form.gender = '';
			this.form.customerCompany = '';
			this.form.mailbox = '';
			this.form.customerIdentity = '';
			this.form.remark = '';
			this.form.customerName = '';
			this.getCustomerSource();
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
			fetchDefaultUsers(localStorage.getItem('user')).then(res => {
				this.users = res.data;
			});
		},
		getListCustomize() {
			let data = {
				companyId: this.query.companyId
			};
			getListCustomize(data).then(res => {
				if (res.code == 200) {
					this.customList = res.data;
					this.customList.forEach((i, index) => {
						this.customize[index] = '';
						i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
						i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
						if (i.options) {
							this.customList[index].options = Array.from(i.options, ({ str }) => str);
						}
					});
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getCustomerSchedule() {
			getCustomerSchedule(this.query.companyId).then(res => {
				if (res.code == 200) {
					this.customerScheduleList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getCustomerSource() {
			getCustomerSource(this.query.companyId).then(res => {
				if (res.code == 200) {
					this.ustomerSourceList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				fetchCompanyByAgentId(agentId).then(res => {
					this.companys = res.data;
				});
			}
		},
		companyChange(companyId) {
			this.query.companyId = companyId;

			this.getListCustomize();
			this.getCustomerSchedule();
			this.getCustomerSource();
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},
		departmentChange(departmentId) {
			let data = {
				departmentId: departmentId,
				currentUserId: localStorage.getItem('user')
			};
			fetchUsersByDepartmentId(data).then(res => {
				this.users = res.data;
				this.query.normalName = '';
				this.form.normalName = '';
				// this.CRMform.normalName = '';
			});
		},
		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		getRemoveCrm(id) {
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let param = {
						ids: id,
						userId: localStorage.getItem('user')
					};
					removeCrm(param).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		getRemoveCrm2() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].id + ',';
			}
			if (str === '') {
				this.$message.error(`请选择线索`);
				return;
			} else {
				this.$confirm('确定要删除吗？', '提示', {
					type: 'warning'
				})
					.then(() => {
						let param = {
							ids: str,
							userId: localStorage.getItem('user')
						};
						removeCrm(param).then(res => {
							if (res.code == 200) {
								this.$message.success('批量删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			}

			this.multipleSelection = [];
		},
		//点击当前行
		showData(row) {
			this.activeName = 'contactInformation';
			this.getfindByRecordId(row.id);
		},
		radioChange(e) {},
		//用户详情
		getfindByRecordId(id) {
			intentionCustomergetDetails(id).then(res => {
				// this.activeName = 'contactInformation';
				this.customerDetails = res.data;
				this.addressSelections = [];
				this.detail = '';
				// this.form.userId= this.customerDetails.normalName;
				this.form = res.data;
				this.form.customizeVo.forEach((i, index) => {
					this.customize[index] = '';
					i.multiSelectDropDownBox = []; //后台获取的时候 就加一个地方存选中的值的  多选的
					// i.textVal = ''; //后台获取的时候 就加一个地方存选中的值的  单选
					if (i.type == 'multi-select-drop-down-box') {
						if (i.integerList.length) {
							i.options.forEach((item, indexs) => {
								i.integerList.forEach(name => {
									if (item.index2 == name) {
										i.multiSelectDropDownBox.push(item.str);
										item.checked = true;
									}
								});
							});
						}
					} else if (i.type == 'region') {
						if (i.textVal) {
							let item = i.textVal.split('-');
							for (let i = 0; i < item.length; i++) {
								console.log(TextToCode[item[0]].code, '0000000');
								if (TextToCode[item[0]]) {
									if (i == 0) {
										this.province = item[i];
										this.addressSelections.push(TextToCode[item[0]].code);
									}
								}
								console.log(TextToCode[item[0]][item[1]].code, '11111');
								if (TextToCode[item[0]][item[1]]) {
									if (i == 1) {
										this.province = item[i];
										this.addressSelections.push(TextToCode[item[0]][item[1]].code);
									}
								}
								console.log(TextToCode[item[0]][item[1]][item[2]].code, '2222');
								if (TextToCode[item[0]][item[1]][item[2]]) {
									if (i == 2) {
										this.town = item[i];
										this.addressSelections.push(TextToCode[item[0]][item[1]][item[2]].code);
									}
								}
								if (i == 3) {
									this.detail = item[i];
								}
							}
							console.log(this.addressSelections, '是是是');
						}
					}
				});
				this.drawerInputDis = true;
				this.drawer = true;
			});
		},
		handleClick(e) {
			this.drawerInputDis = true;
			this.callRecordBox = false;
			if (e.props.label == '通话记录') {
				this.getfindCallRecord();
			}
		},
		getfindCallRecord() {
			getfindCallRecord(this.customerDetails.id).then(res => {
				if (res.code == 200) {
					this.followUpRecordData = res.data;
				}
			});
		},
		handleChangeAudioVolume() {
			if (this.$refs.audio) this.$refs.audio.audioHuds = false;
		},
		toObject(arr) {
			this.rv = {};
			for (let i = 0; i < arr.length; ++i) this.rv[i] = arr[i];
			return this.rv;
		},
		updateIntentionCustomer() {
			this.form.customize = [];
			this.form.customizeVo.forEach((item, index) => {
				if (item.type == 'multi-select-drop-down-box') {
					if (item.multiSelectDropDownBox) {
						this.form.customize[index] = item.multiSelectDropDownBox.toString();
					}
				} else if (item.type == 'region') {
					this.form.customize[index] = this.addressStr + '-' + this.detail;
				} else {
					this.form.customize[index] = item.textVal;
				}
			});
			this.form.userId = localStorage.getItem('user');
			this.form.customize = this.toObject(this.form.customize); //变成对象
			this.form.web = true;
			if (this.role != 'normal') {
				if (this.form.normalName == '') {
					this.$message.error('请选择跟进人员');
					return;
				}
			} else {
				this.form.normalName = localStorage.getItem('user');
			}
			this.form.customizeVo = null;
			this.form.companyId = this.query.companyId;

			console.log(this.form, '2222222222222222');
			updateIntentionCustomer(JSON.stringify(this.form)).then(res => {
				if (res.code == 200) {
					this.getupdateSchedule();
				}
			});
		},
		getAddIntentionCustomer() {
			this.customList.forEach((item, index) => {
				if (item.type == 'multi-select-drop-down-box') {
					if (item.multiSelectDropDownBox) {
						this.form.customize[index] = item.multiSelectDropDownBox.toString();
					}
				} else {
					if (item.type == 'date') {
						this.form.customize[index] = item.textVal;
					} else {
						this.form.customize[index] = item.textVal;
					}
				}
			});

			this.form.userId = localStorage.getItem('user');
			this.form.customize = this.toObject(this.form.customize); //变成对象
			this.form.web = true;
			if (this.role == 'normal') {
				this.form.normalName = this.users[0].value;
			} else {
				if (this.form.normalName == '') {
					this.$message.error('请选择跟进人员');
					return;
				}
			}

			addIntentionCustomer(JSON.stringify(this.form)).then(res => {
				if (res.code == 200) {
					this.$message.success(res.message);
					this.addVisible = false;
					this.getData();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		closeDialog(formName) {
			this.companyDis = false;
			this.userDis = false;
			this.$refs[formName].resetFields();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 获取数据
		uploadExcel() {
			this.upload = true;
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}
			return y + '-' + m + '-' + d;
		},
		getTime() {
			this.timeArr.push(this.query.startTime);
			this.timeArr.push(this.query.endTime);
			this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
			this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
		},
		getData() {
			this.loading = true;
			this.tableData = [];
			this.query.userId = localStorage.getItem('user');
			this.query.company = localStorage.getItem('company');
			CRMpublicData(this.query).then(res => {
				this.loading = false;
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		handleDownload() {
			this.loading = true;
			downloadIntentionCustomerList(this.query).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = 'CRM报表' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				this.loading = false;
			});
		},
		getSchedule(value) {
			this.query.schedule = this.query.schedule == value ? '' : value;
			this.scheduleKey = !this.scheduleKey;
		},
		getSource(value) {
			this.query.source = this.query.source == value ? '' : value;
			this.sourceKey = !this.sourceKey;
		},
		getSource2(value) {
			this.form.source = this.form.source == value ? '' : value;
		},
		getNotUpdated(value) {
			this.query.notUpdated = this.query.notUpdated == value ? '' : value;
			this.notUpdatedKey = !this.notUpdatedKey;
		},
		getType(value) {
			this.query.type = this.query.type == value ? '' : value;
			this.typeKey = !this.typeKey;
		},
		getGender(value) {
			this.query.gender = this.query.gender == value ? '' : value;
			this.genderKey = !this.genderKey;
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		handleEdit() {
			this.activeName = 'contactInformation';
			this.drawerInputDis = false;
		},

		getNoupdateIntentionCustomer() {
			this.drawerInputDis = true;
		},

		getCustomerReleases() {
			if (this.form.companyId) {
				getCustomerRelease(this.form.companyId).then(res => {
					if (res.code == 200) {
						this.shifangList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				getCustomerRelease(localStorage.getItem('company')).then(res => {
					if (res.code == 200) {
						this.shifangList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		shifangRadioChange(e) {
			this.shifangbox.shifangVal = e;
		},
		deleteIntentionCustomers() {
			let data = {
				id: this.shifangId,
				cause: this.shifangbox.shifangVal,
				userId: localStorage.getItem('user')
			};
			deleteIntentionCustomer(data).then(res => {
				if (res.code == 200) {
					this.$message.success('释放成功');
					this.shifangId = '';
					this.shifangbox.shifangVal = '';
					this.shifang = false;
					this.getData();
				} else {
					this.$message.error(res.message);
				}
			});
		},
		closeshifangDialog() {
			this.shifang = false;
			this.shifangbox.shifangVal = '';
			this.shifangId = '';
		},
		// 释放操作
		handleDelete(id) {
			this.shifang = true;
			this.getCustomerReleases();
			this.shifangId = id;
		},
		handleDelete2() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].id + ',';
			}
			if (str === '') {
				this.$message.error(`请选择线索`);
				return;
			} else {
				this.shifang = true;
				this.getCustomerReleases();
				this.shifangId = str;
			}
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		},

		getupdateSchedule() {
			let data = {
				id: this.customerDetails.id,
				schedule: this.form.scheduleIndex
			};
			updateSchedule(data).then(res => {
				if (res.code == 200) {
					this.drawerInputDis = true;
					this.getData();
					this.getfindByRecordId(this.customerDetails.id);
					this.$message.success(res.message);
				} else {
					this.drawerInputDis = false;
					this.$message.error(res.message);
				}
			});
		},
		tapNav(e) {
			this.tapnav = e;
			this.form.scheduleIndex = e;
			this.getupdateSchedule();
		},
		showcallRecordBox() {
			this.callRecordBox = true;
		},
		hidecallRecordBox() {
			this.callRecordBox = false;
		},
		GetIntentionCustomeraddJournals() {
			let data = {
				id: this.customerDetails.id,
				journal: this.journal
			};
			GetIntentionCustomeraddJournal(data).then(res => {
				if (res.code == 200) {
					this.callRecordBox = false;
					this.getfindByRecordId(this.customerDetails.id);
				} else {
					this.getfindByRecordId(this.customerDetails.id);
					this.$message.error(res.message);
				}
			});
		},

		getPublicData() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].id + ',';
			}
			if (str === '') {
				this.$message.error(`请选择线索`);
				return;
			} else {
				this.transferVisible2 = true;
			}
		},
		getPublicData2() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].id + ',';
			}
			if (str === '') {
				this.$message.error(`请选择线索`);
				return;
			} else {
				let data = {
					userId: localStorage.getItem('user'),
					ids: str,
					normalId: this.form.userId
				};
				getPublicData(data).then(res => {
					if (res.code == 200) {
						this.getData();
						this.transferVisible2 = false;
						this.$message.success(res.data);
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		// 导入弹出
		uploadExcel() {
			this.upload = true;
		},
		closeUpload() {
			this.upload = false;
			this.$refs.upload.clearFiles();
		},

		handleRemove(file, fileList) {
			console.log(file, fileList, 'handleRemove');
		},
		handlePreview(file) {
			console.log(file, 'handlePreview');
		},
		uploadSuccess(success) {
			console.log(success, '对对对');
			if (success.code == 200) {
				this.$message.success('上传成功');
			} else {
				this.$message.error(success.message);
				this.filterBox = true;
				this.filterListData = success.customData;
			}
			this.loading = false;
			this.$refs.upload.clearFiles();
			this.upload = false;
			this.getData();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 7px 16px;
	font-size: 12px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
}

.user-box .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
}
.drawer-box {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.drawer-box .header {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.drawer-box .header .user-box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.drawer-box .header .user-box .user-name {
	display: flex;
	flex-direction: row;
}
.drawer-box .header .user-box .user-name img {
	width: 50px;
	height: 50px;
	margin-right: 20px;
}
.drawer-box .header .user-box .user-name .name div {
	color: #000;
	font-weight: bold;
}
.drawer-box .header .user-box .user-name .name .el-input.is-disabled .el-input__inner {
	color: #746f69;
	font-weight: bold;
}
.drawer-box .header .user-info {
	display: flex;
	flex-direction: row;
	margin: 10px 0;
}
.drawer-box .header .user-info .list {
	margin-right: 100px;
}
.drawer-box .header .user-info .list .name-label {
	color: #000;
	font-weight: 600;
}
.drawer-box .header .user-info .list .name-data {
	color: #000;
}
.drawer-box .nav-tab {
	border-top: 10px solid #f1f2f6;
	border-bottom: 10px solid #f1f2f6;
}
.drawer-box .nav-tab .ul-box {
	display: flex;
	flex-direction: row;

	width: 70%;
	margin: 20px 0;
	border-radius: 20px;
}
.drawer-box .nav-tab .ul-box div {
	height: 40px;
	text-align: center;
	line-height: 40px;
	width: 25%;
	margin-right: -10px;
	background-color: #f1f2f6;
	cursor: pointer;
}

.drawer-box .nav-tab .ul-box div:first-child {
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	/* clip-path: circle(100% at 30% 10%); */
}
.drawer-box .nav-tab .ul-box div:last-child {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	/* clip-path: circle(40% 0%, 40% 20%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 20%,100% at 30% 10%); */
}

.drawer-box .nav-tab .ul-box .active {
	border: 2px solid #28b87d;
	background-color: #fff;
	color: #28b87d;
	height: 36px;
	line-height: 36px;
	margin-right: 1px;
}
.drawer-box .drawer-box .drawer-content .title {
	margin-bottom: 6px;
	font-weight: 600;
}

.drawer-box .add-journal {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #3f8afd;
	color: #fff;
	font-size: 30px;
	position: absolute;
	bottom: 30px;
	left: 30px;
	text-align: center;
	cursor: pointer;
}
.drawer-box .drawer-content .title span {
	width: 4px;
	height: 14px;
	position: relative;
	top: 2px;
	display: inline-block;
	background-color: #408bfe;
}
.drawer-box .drawer-content .call-record-box {
	display: flex;
	flex-direction: row;
}

.drawer-box .drawer-content .call-record-box .call-list .item-info {
	width: 50%;
	float: left;
	margin-bottom: 6px;
	line-height: 40px;
	min-height: 45px;
}
.drawer-box .drawer-content .call-record-box .call-list .item-info .el-input.is-disabled .el-input__inner {
	color: #373635;
}
.drawer-box .drawer-content .call-record-box .call-list .item-info .lat {
	display: inline-block;
	width: 100px;
	font-weight: 500;
	color: #000;
	line-height: 40px;
	float: left;
}

/* radio单选 变成正方形 */
::v-deep .el-radio__inner {
	border-radius: 2px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
	content: '';
	width: 10px;
	height: 5px;
	border: 1px solid #c0c4cc;
	border-top: transparent;
	border-right: transparent;
	text-align: center;
	display: block;
	position: absolute;
	top: 2px;
	left: 0px;
	vertical-align: middle;
	transform: rotate(-45deg);
	border-radius: 0px;
	background: none;
}
::v-deep .el-icon-time,
::v-deep .el-icon-date {
	display: none;
}

.icon-style {
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-size: 12px;
	color: #666;
	cursor: pointer;
	margin: 20px 10px;
	height: 40px;
	line-height: 40px;
}
.icon-style:hover {
	background-color: #e5e9f0;
}
.icon-style i {
	font-size: 16px;
	position: relative;
	height: 40px;
	line-height: 40px;
	left: -6px;
}
.query-list .el-form-item {
	padding: 6px 20px !important;
	margin-bottom: 0 !important;
}
.query-list .el-form-item:hover {
	background-color: #e5e9f0;
	cursor: pointer;
}
.loyo-folding-btn:hover {
	border-color: #2c9dfc;
}
.loyo-folding-btn {
	position: relative;
	color: #2c9dfc;
	height: 20px;
	text-align: center;
	border-top: 1px solid #e5e9f0;
	margin-top: 15px;
	cursor: pointer;
}
.loyo-folding-btn a {
	position: absolute;
	bottom: -1px;
	border-bottom: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-top: 3px solid #ddd;
	border-top-color: #fff;
	width: 60px;
	height: 20px;
	line-height: 20px;
}
.loyo-folding-btn a i {
	font-size: 18px;
	color: #ddd;
}
.loyo-folding-btn:hover i {
	color: #2c9dfc;
}
.loyo-folding-btn:hover a {
	/* border: 1px solid #2c9dfc; */
	border-bottom: 1px solid #2c9dfc;
	border-left: 1px solid #2c9dfc;
	border-right: 1px solid #2c9dfc;
	border-top: 3px solid #2c9dfc;
	border-top-color: #fff;
}
</style>
<style type="text/css">
.el-drawer {
	/* height: calc(100% - 165px) !important; */
	padding: 16px !important;
	font-size: 12px !important;
	line-height: 1.5 !important;
	word-wrap: break-word !important;
	position: absolute !important;
	/* top: 165px !important; */
	overflow: auto !important;
}
.input-with-select .el-input {
	width: 130px;
}
.input-with-select .el-input-group__prepend {
	background-color: #fff;
}
.drawer-box .bordis {
	border: none;
	background-color: #fff;
}
.drawer-box .el-input.is-disabled .el-input__inner {
	border: none;
	padding: 0;
	color: #746f69;
	background-color: #fff;
}
.user-box .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
}
</style>
